import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
// import { EventList } from '/src/components/events'
import { LinkedList } from '/src/components/common'

const LinkedListItem = ({ title, content }) => {
  return (
    <>
      <h5 className="mb-3">{title}</h5>
      <p>{content}</p>
    </>
  )
}

const CommunityPage = () => {
  return (
    <Layout hero={<HeroImage title="Community" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <Row>
            <Col sm={12}>
              <p>We are not just about distributing and manufacturing steel. We have a responsibility to enhance the bond between the community and our business.</p>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mt-5 mb-5">
            <Col sm={6}>
              <StaticImage 
                src="../../images/community.jpeg" 
                alt="Distribution"
                width={570}
                height={384}
                quality={100}
                placeholder="blurred"
                layout="fullWidth" />
            </Col>
            <Col sm={6}>
              <p>While we are a business focused on the supply of Australian steel, we also recognise the importance of providing support to the wider community.
              It's important to us to be involved in projects that benefit Australians - whether it's helping with disaster relief, 
              fund-raising for a good cause or helping make life a bit more fun.</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mb-5">
        <Col xs={10}>
          <div className="title-container mb-5">
            <h1 className="title">
              We support local communities by providing
            </h1>
          </div>
          <LinkedList items={[
            {
              content: <LinkedListItem 
                title="Orrcon Steel BBQ Trailers"
                content="" />,
              path: '/community/distribution'
            },
            {
              content: <LinkedListItem 
                title="Local Sporting Teams & Events"
                content="" />,
              path: '/community/partners'
            },
            {
              content: <LinkedListItem 
                title="Mill Tours & Community Activities"
                content="" />,
              path: '/community/history'
            }
          ]} />
        </Col>
      </Row>
    </Layout>
  )
}

export default CommunityPage

export const Head = () => (
  <>
    <title>Our Community | Orrcon Steel</title>
    <meta name="description" content="While we are a business focused on the supply of Australian steel, we also recognise the importance of providing support to the wider community." />
  </>
)
